import { CouponUsing } from '../types';
import { FC, useEffect, useState } from 'react';
import { Paper, TableContainer } from '@mui/material';
import { TableView } from '../../../shared/components';
import { formatDateTime } from '../../../shared/tools';

type Props = {
  usings: CouponUsing[];
};

export const CouponsItemTableView: FC<Props> = ({ usings }) => {
  const [items, setItems] = useState<CouponUsing[]>([]);
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
    total: usings.length,
  });

  const getItems = () => {
    const size = Math.min(pagination.size, usings.length);
    const startIndex = pagination.page * pagination.size;
    const endIndex = pagination.size * (pagination.page + 1);
    return usings.slice(startIndex, endIndex);
  };

  useEffect(() => {
    setItems(getItems());
  }, [pagination, usings]);

  const changePage = (page: number) => setPagination({ ...pagination, page });
  const changePageSize = (pageSize: number) =>
    setPagination({ ...pagination, page: 0, size: pageSize });

  return (
    <TableContainer component={Paper}>
      <TableView
        records={items}
        notFoundText="No coupon data"
        columns={[
          {
            title: 'Created At',
            cell: r => formatDateTime(r.createdAt),
          },
          {
            title: 'Status',
            cell: r => r.statusId,
          },
          {
            title: 'Email',
            cell: r => r.email,
          },
          {
            title: 'IP Address',
            cell: r => r.clientIpAddress,
          },
          {
            title: 'Completed At',
            cell: r => formatDateTime(r.completedAt),
          },

          {
            title: 'Last Step',
            cell: r => r.lastStep,
          },
          {
            title: 'Last Step Number',
            cell: r => r.lastStepNumber,
          },
          {
            title: 'Total Steps',
            cell: r => r.totalStepCount,
          },
          {
            title: 'Request Id',
            cell: r => r.requestId,
          },
          {
            title: 'Id',
            cell: r => r.id,
          }
        ]}
        page={{
          pagination,
          pageSizes: [10, 50, 100, 500].filter(value => value < usings.length),
          onChangePage: changePage,
          onChangePageSize: changePageSize,
        }}
      />
    </TableContainer>
  );
};
